<script>
import Vue from "vue";
import BaseVue from "@frontend/Base.vue";
import Gen from "../helper/Gen";
export default {
  extends: BaseVue,
  data() {
    return {
      row: false,
    };
  },
  computed: {},
  async mounted() {
    await this.ready();
    this.getStatic();
  },
  methods: {
    getStatic() {
      this.loadingOverlay = true;
      Gen.apirest("/static-content/7", {}, (err, resp) => {
        this.loadingOverlay = false;
        if (err) return;
        if (!resp.row) {
          window.location = this.$router.resolve({ name: "Home" }).href;
          return;
        }
        Object.keys(resp).forEach((k) => {
          this[k] = resp[k];
        });
      });
    },
  },
};
</script>
<template>
  <div>
    <section id="content">
      <div class="content-wrap">
        <section class="section py-sm">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="heading-block center border-0">
                  <h2 itemprop="alternativeHeadline">{{ row.title || "Kebijakan Privasi" }}</h2>
                </div>
                <div class="legal-text">
                  <div
                    itemprop="description"
                    class="tucupampam"
                    style="z-index: 100;"
                    v-html="row.description"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>
